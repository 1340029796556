<template>
  <el-container class="video-list-container">
    <el-main>
      <el-card>
        <div slot="header">
          <span>添加采集</span>
        </div>
        <div>
          <div class="search-bar">
            <el-form :inline="true" :model="ytSearchForm" @submit.native.prevent>
              <el-input style="width:50%;" v-model="ytSearchForm.search" placeholder="输入关键词"
                        class="search-input"></el-input>
              <el-button type="primary" @click="ytSearch" :disabled="caijisousuo">搜索</el-button>
            </el-form>
          </div>
          <el-divider></el-divider>
          <div v-if="ytSearchResult.length > 0">
            <el-checkbox-group>
              <el-row class="box_box">
                <el-col>
                  <el-card v-for="item in ytSearchResult" :key="item.id.videoId" class="box-card"
                           :body-style="{padding: '8px' }">
                    <el-checkbox
                        class="video_id"><br/></el-checkbox>
                    <img class="img" :src="item.snippet.thumbnails.medium.url"/>
                    <div class="text">{{ item.snippet.title }}：{{ item.snippet.publishTime }}</div>
                    <div class="action">
                      <el-button type="success" @click="downloadYTVideo(item.id.videoId)">采集</el-button>
                    </div>
                  </el-card>
                </el-col>
              </el-row>
            </el-checkbox-group>
            <div class="page_box" v-if="ytSearchForm.totalNum > 0">
              <el-pagination
                  :page-size="14"
                  :page-sizes="[14, 28, 42, 56]"
                  layout="sizes,prev, next"
                  @current-change="getListVideo"
                  @size-change="handleSizeChange"
                  :current-page.sync="ytSearchForm.page"
                  :total="ytSearchForm.totalNum">
              </el-pagination>
            </div>
          </div>
          <div v-else class="text-center mt-4">无数据</div>
        </div>
      </el-card>
      <el-divider></el-divider>
      <el-card>
        <div slot="header">
          <span>视频管理</span>
        </div>
        <div>
          <div class="search-bar">
            <el-form :inline="true" :model="dlSearchForm" @submit.native.prevent>
              <el-input style="width:50%" v-model="dlSearchForm.search" placeholder="输入关键词" class="search-input"
                        @keyup.enter.native="dlSearch"></el-input>
              <el-button type="primary" @click="dlSearch">搜索</el-button>
              <el-button type="primary" @click="qx()">全选</el-button>
              <el-button type="danger" @click="del1()">批量删除</el-button>
              <el-button type="primary" @click="batchDownload">批量下载</el-button>
              <el-button type="primary" @click="batchAnalyze">批量分析</el-button>
            </el-form>
          </div>
          <div v-if="dlSearchResult.length > 0">
            <el-checkbox-group v-model="checkList">
              <el-row class="box_box">
                <el-col>
                  <el-card v-for="(item,index) in dlSearchResult" :key="index" class="box-card"
                           :body-style="{padding: '8px' }">
                    <el-checkbox
                        :value="item.video_id"
                        :label="item.video_id"
                        class="video_id"><br/></el-checkbox>
                    <img class="img" :src="item.cover_url"
                         onerror="src='https://develop-hello-orange.oss-cn-beijing.aliyuncs.com/nanhai_20180919184639.png'"
                    />
                    <div class="text">{{ item.title }}：{{ item.publish_time }}</div>
                    <div class="action">
                      <el-button v-if="item.status_analyze === 1" type="success" @click="addAnalyze(item.video_id)">
                        加入分析
                      </el-button>
                      <el-tag v-if="item.status_analyze === 2">分析中</el-tag>
                      <router-link :to="'/custom/customChart/'+item.video_id+'?type=1&attitude='+item.attitude">
                        <el-tag v-if="item.status_analyze === 3"
                                type="success"
                                @click="viewAnalysis(item.video_id)"
                        >已完成
                        </el-tag>
                      </router-link>
                      <el-tag v-if="item.status_analyze === 4" type="danger">无法分析</el-tag>
                      <el-button type="success" :ref="'downloadButton' + index"
                                 @click="startDownload(item.play_path, item.play_path, index)">下载
                      </el-button>
                      <el-button type="danger" @click="deleteVideo(item.video_id)" :data-id="item.video_id">删除
                      </el-button>
                      <router-link :to="'/custom/customPlay/' + item.video_id + '?type=' + item.status_analyze">
                        <el-button type="primary">播放</el-button>
                      </router-link>
                    </div>
                  </el-card>
                </el-col>
              </el-row>
            </el-checkbox-group>
            <div class="page_box" v-if="dlSearchForm.totalNum > 0">
              <el-pagination
                  :page-size="14"
                  :page-sizes="[14, 28, 42, 56]"
                  layout="sizes,prev, next, jumper,total"
                  @current-change="getListVideo2"
                  @size-change="handleSizeChange2"
                  :current-page.sync="dlSearchForm.page"
                  :total="dlSearchForm.totalNum">
              </el-pagination>
            </div>
          </div>

          <div v-else class="text-center mt-4">无数据</div>
        </div>
      </el-card>
    </el-main>
    <div id="loading" class="text-center" v-show="loading">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span class="loading-text">{{ loadingText }}</span>
    </div>
  </el-container>
</template>

<script>
import axios from "axios";
import store from "@/store";
import {delvideo1} from "@/api/vedio";

export default {
  name: 'index',
  data() {
    return {
      checkList: [],
      checkAll: [],
      activeNames: ['yt-search'],
      ytSearchForm: {
        search: '',
        pageToken: '',
        token: '',
        page: 1,
        perPage: 12,
        totalNum: 0,
        prevPageToken: '', //上一页页码token
        nextPageToken: '', //下一页页码token
      },
      dlSearchForm: {
        search: '',
        token: '',
        page: 1,
        perPage: 12,
        totalNum: 0,
        dldisabled: false,
      },
      ytSearchResult: [],
      dlSearchResult: [],
      loading: false,
      loadingText: '',
      caijisousuo: false,
      isCheckAll: false,
    };
  },
  methods: {
    batchAnalyze() {
      // 检查是否有选中的视频
      if (this.checkList.length === 0) {
        this.$message({
          message: '请先选择要分析的视频',
          type: 'warning'
        });
        return;
      }
      // 遍历选中的视频进行加入分析操作
      this.checkList.forEach(videoId => {
        this.addAnalyze(videoId);
      });
    },
    batchDownload() { //批量下载视频
      // 检查是否有选中的视频
      if (this.checkList.length === 0) {
        this.$message({
          message: '请先选择要下载的视频',
          type: 'warning'
        });
        return;
      }
      // 遍历选中的视频进行下载
      this.checkList.forEach((videoId, index) => {
        const item = this.dlSearchResult.find(item => item.video_id === videoId);
        if (item) {
          this.startDownload(item.play_path, item.play_path, index);
        }
      });
    },
    del1(){ //删除多个视频

      this.$confirm('此操作将删除该视频, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delvideo1(this.checkList).then((res)=>{
           this.dlSearch();
        })
      })

    },
    async startDownload(url, name, index) {
      try {
        this.$refs[`downloadButton${index}`][0]['$el'].disabled = true;
        const response = await fetch(url);
        if (!response.body) {
          throw {code: 404, message: 'Not Found'};
        }
        const contentLength = response.headers.get('content-length');
        if (!contentLength) {
          throw {code: 400, message: "Content-Length: 0"};
        }
        const total = parseInt(contentLength, 10);
        let loaded = 0;

        const updateProgress = ({loaded, total}) => {
          const progress = Math.round((loaded * 100) / total);
          this.updateButtonText(`下载中...(${progress})%`, index);
        };

        const reader = response.body.getReader();
        const res = new Response(new ReadableStream({
          async start(controller) {
            for (; ;) {
              const {done, value} = await reader.read();
              if (done) break;
              loaded += value.byteLength;
              updateProgress({loaded, total});
              controller.enqueue(value);
            }
            controller.close();
          },
        }));

        const blob = await res.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = name;
        link.click();
        this.$message({
          message: '下载成功',
          type: 'success'
        })
      } catch (error) {
        this.$message({
          message: '下载失败',
          type: 'error'
        })
      } finally {
        this.$refs[`downloadButton${index}`][0]['$el'].disabled = false;
        this.updateButtonText('下载', index);
      }
    },
    updateButtonText(text, index) {
      this.$refs[`downloadButton${index}`][0]['$el'].textContent = text;
    },
    qx() { //全选
      if (this.isCheckAll) {
        this.checkList = []
        this.isCheckAll = false
      } else {
        this.isCheckAll = true
        this.checkList = this.checkAll
      }
    },
    // 模拟异步搜索 YouTube 视频
    async ytSearch() {
      // 这里可以使用 axios 或其他方式发送请求
      // 假设请求成功后更新 ytSearchResult
      const keyword = this.ytSearchForm.search;
      this.caijisousuo = true;
      axios.post('/nanhai_server/index.php/admin/YtVideo/yt_search_ajax', {search: keyword, token: store.getters.token})
          .then(response => {
            // 处理成功响应
            console.log(response.data);
            if (typeof response.data.data.data.items !== 'undefined')
              this.ytSearchResult = response.data.data.data.items;

            this.ytSearchForm.totalNum = response.data.data.total;
            this.ytSearchForm.perPage = response.data.data.perPage;
            this.ytSearchForm.prevPageToken = response.data.data.data.prevPageToken ?? '';
            this.ytSearchForm.nextPageToken = response.data.data.data.nextPageToken ?? '';
            this.caijisousuo = false;
          })
          .catch(error => {
            // 处理错误响应
            console.error(error);
          });

      // this.ytSearchResult = [
      //   {
      //     id: { videoId: 'video1' },
      //     snippet: {
      //       title: 'Video Title 1',
      //       publishTime: '2023-01-01',
      //       thumbnails: {
      //         medium: { url: 'image-url-1' },
      //       },
      //     },
      //   },
      // ];
    },
    async getListVideo(val) {
      console.log(val)
      let pageToken;
      if (val > this.ytSearchForm.page) { //下一页
        pageToken = this.ytSearchForm.nextPageToken;
      } else {
        pageToken = this.ytSearchForm.prevPageToken;
      }
      // 这里可以使用 axios 或其他方式发送请求
      // 假设请求成功后更新 ytSearchResult
      const keyword = this.ytSearchForm.search;
      axios.post('/nanhai_server/index.php/admin/YtVideo/yt_search_ajax', {
        search: keyword,
        token: store.getters.token,
        pageToken: pageToken
      })
          .then(response => {
            // 处理成功响应
            console.log(response.data);
            if (typeof response.data.data.data.items !== 'undefined')
              this.ytSearchResult = response.data.data.data.items;

            this.ytSearchForm.totalNum = response.data.data.total;
            this.ytSearchForm.perPage = response.data.data.perPage;
          })
          .catch(error => {
            // 处理错误响应
            console.error(error);
          });
    },
    async handleSizeChange(perPage) {
      // 这里可以使用 axios 或其他方式发送请求
      // 假设请求成功后更新 ytSearchResult
      const keyword = this.ytSearchForm.search;
      axios.post('/nanhai_server/index.php/admin/YtVideo/yt_search_ajax', {
        search: keyword,
        token: store.getters.token,
        perPage: perPage
      })
          .then(response => {
            // 处理成功响应
            console.log(response.data);
            if (typeof response.data.data.data.items !== 'undefined')
              this.ytSearchResult = response.data.data.data.items;

            this.ytSearchForm.totalNum = response.data.data.total;
            this.ytSearchForm.perPage = response.data.data.perPage;
          })
          .catch(error => {
            // 处理错误响应
            console.error(error);
          });
    },
    // 模拟异步搜索下载视频
    async dlSearch() {
      // 这里可以使用 axios 或其他方式发送请求
      // 这里可以使用 axios 或其他方式发送请求
      // 假设请求成功后更新 ytSearchResult
      const keyword = this.dlSearchForm.search;
      this.dlSearchForm.dldisabled = true;
      axios.post('/nanhai_server/index.php/admin/YtVideo/dl_search_ajax', {search: keyword, token: store.getters.token})
          .then(response => {
            // 处理成功响应
            console.log(response.data);
            if (typeof response.data.data.items !== 'undefined')
              this.dlSearchResult = response.data.data.items;

            this.dlSearchForm.totalNum = response.data.data.total;
            this.dlSearchForm.perPage = response.data.data.perPage;

            this.dlSearchResult.forEach((item) => {
              this.checkAll.push(item.video_id)
            })

            this.dlSearchForm.dldisabled = false;
          })
          .catch(error => {
            // 处理错误响应
            console.error(error);
          });

      // 假设请求成功后更新 dlSearchResult
      // this.dlSearchResult = [
      //   {
      //     video_id: 'video2',
      //     title: 'Downloaded Video Title',
      //     cover_url: 'image-url-2',
      //     publish_time: '2023-02-02',
      //     status_analyze: 1,
      //     play_path: 'download-url',
      //   },
      //   {
      //     video_id: 'video2',
      //     title: 'Downloaded Video Title',
      //     cover_url: 'image-url-2',
      //     publish_time: '2023-02-02',
      //     status_analyze: 1,
      //     play_path: 'download-url',
      //   },
      // ];
    },
    // 模拟下采集 YouTube 视频
    async downloadYTVideo(vid) {
      console.log(vid)
      console.log(this.ytSearchResult)
      // 实现下载逻辑
      const videoItem = this.ytSearchResult.find((video) => video.id.videoId === vid);
      console.log(videoItem)
      const data = {
        vid,
        title: videoItem.snippet.title.trim(),
        image: videoItem.snippet.thumbnails.medium.url,
        publish_time: videoItem.snippet.publishTime.trim(),
        token: store.getters.token,
      };
      this.loading = true;
      this.loadingText = '下载中...';
      axios.post('/nanhai_server/index.php/admin/ytvideo/yt_download', data)
          .then((response) => {
            this.loading = false;
            if (response.data.code === 0) {
              this.$message({
                message: '采集成功',
                type: 'success'
              })
              // 如果有 dl_search 方法，可以调用它
              // this.dl_search();
              this.dlSearch();
            } else {
              this.$message({
                message: response.data.msg,
                type: 'error'
              })
            }
          })
          .catch((error) => {
            this.loading = false;
          });
    },
    async getListVideo2(page) { //跳转页面
      console.log(page)
      const keyword = this.dlSearchForm.search;
      this.dlSearchForm.dldisabled = true;
      axios.post('/nanhai_server/index.php/admin/YtVideo/dl_search_ajax', {
        search: keyword,
        token: store.getters.token,
        page: page
      })
          .then(response => {
            // 处理成功响应
            console.log(response.data);
            if (typeof response.data.data.items !== 'undefined')
              this.dlSearchResult = response.data.data.items;

            this.dlSearchForm.totalNum = response.data.data.total;
            this.dlSearchForm.perPage = response.data.data.perPage;
            this.dlSearchForm.dldisabled = false;
          })
          .catch(error => {
            // 处理错误响应
            console.error(error);
          });
    },
    async handleSizeChange2(perPage) { //选择每页多少条
      console.log(perPage)
      const keyword = this.dlSearchForm.search;
      this.dlSearchForm.dldisabled = true;
      axios.post('/nanhai_server/index.php/admin/YtVideo/dl_search_ajax', {
        search: keyword,
        token: store.getters.token,
        perPage: perPage
      })
          .then(response => {
            // 处理成功响应
            console.log(response.data);
            if (typeof response.data.data.items !== 'undefined')
              this.dlSearchResult = response.data.data.items;

            this.dlSearchForm.totalNum = response.data.data.total;
            this.dlSearchForm.perPage = response.data.data.perPage;
            this.dlSearchForm.dldisabled = false;
          })
          .catch(error => {
            // 处理错误响应
            console.error(error);
          });
    },

    // 模拟删除视频
    deleteVideo(id) {
      if (!confirm('Are you sure to delete this item?')) return;
      const loadingHtml = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> 删除中...';
      const button = this.$el.querySelector(`[data-id="${id}"]`);
      button.innerHTML = loadingHtml;
      button.disabled = true;
      const authToken = store.getters.token; // 根据实际情况获取 token 的方法
      axios.get(`/nanhai_server/index.php/admin/ytvideo/dl_delete?id=${id}&token=${authToken}`)
          .then(() => {
            this.dlSearch(); // 如果有 dl_search 方法，可以调用它
            button.innerHTML = '删除'
          })
          .catch(() => {
            button.innerHTML = '删除';
            button.disabled = false;
          });
    },
    // 模拟加入分析
    addAnalyze(vid) {
      // 实现加入分析逻辑
      const url = `/nanhai_server/index.php/admin/video/${vid}/add_wait`;
      const data = {
        token: store.getters.token
      };
      axios.post(url, data)
          .then(response => {
            if (response.data.code === 0) {
              this.$message({
                message: '加入分析成功',
                type: 'success'
              })
              this.dlSearch(); // 如果有 dl_search 方法，可以调用它
            } else {
              this.$message({
                message: response.data.msg,
                type: 'error'
              })
            }
          })
          .catch(error => {
            console.error(error);
          });
    },
    // 模拟查看分析结果
    viewAnalysis(id) {
      // 实现查看分析结果逻辑
    },
  },
  created() {
    this.dlSearch()
  }
};
</script>

<style lang="scss" scoped>

.video-list-container {
  .search {
    margin-left: 1.25em;
    margin-bottom: 14px;
  }

  .box_box {
    display: flex;
    flex-wrap: wrap;
    margin-left: 1em;
    margin-right: 1.25em;
  }

  .box-card {
    margin: 8px;
    width: 200px;
    display: inline-block;
    position: relative;

    .img {
      width: 180px;
      height: 120px;
    }

    .el-card__header {
      width: 15em;
      height: 10em;

      div {
        text-align: center;
      }
    }

    .video_id {
      position: absolute;
      top: 0;
      left: 0;

    }

    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      font-size: 12px;
      line-height: 20px;
      height: 64px;
      margin: 6px 6px 3px 6px;

    }
  }
}
#loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  color:green;
}
</style>
