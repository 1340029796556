import { render, staticRenderFns } from "./trashcan.vue?vue&type=template&id=4c44716f&scoped=true"
import script from "./trashcan.vue?vue&type=script&lang=js"
export * from "./trashcan.vue?vue&type=script&lang=js"
import style0 from "./trashcan.vue?vue&type=style&index=0&id=4c44716f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c44716f",
  null
  
)

export default component.exports