<template>
  <iframe src="https://driver.ugda.cn/embed/6oi1d7ffa38485b0c460aafd40f5ec39ce763?toolbar=false&layout=grid&appearance=light&themecolor=green" scrolling="no" frameborder="0" allowfullscreen=true width="100%" height="600" title="嵌入的代码" ></iframe>
</template>

<script>
export default {
  name: "shipinjingxuanku"
}
</script>

<style scoped>

</style>
