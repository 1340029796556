import { render, staticRenderFns } from "./setJoinWord.vue?vue&type=template&id=141937f9&scoped=true"
import script from "./setJoinWord.vue?vue&type=script&lang=js"
export * from "./setJoinWord.vue?vue&type=script&lang=js"
import style0 from "./setJoinWord.vue?vue&type=style&index=0&id=141937f9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "141937f9",
  null
  
)

export default component.exports