<template>
  <div>
    <div class="head404"></div>
    <div class="txtbg404">
      <div class="txtbox">
        <p>对不起，您请求的页面不存在、或已被删除、或暂时不可用</p>
        <p class="paddingbox">请点击以下链接继续浏览网页</p>
        <p>》<a style="cursor:pointer" onclick="history.back()">返回上一页面</a></p>
        <p>》<a href="http://www.baidu.com">返回网站首页</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '404'
}
</script>

<style scoped lang='scss'>
.txtbg404 {
  text-align: center;
  margin-top: 300px;
}
</style>
